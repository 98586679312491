<template>
    <v-row>
        <v-col cols="12" lg="7" xl="6" class="leftside d-none d-md-flex align-center justify-center position-relative">
            <v-container>
                <div class="pa-10">
                    <v-row justify="center">
                        <v-col cols="8" xl="5">
                            <div>
                                <h2
                                    class="display-1 white--text font-weight-medium"
                                >CPAider</h2>
                                <h6
                                    class="subtitle-1 mt-4 white--text op-5 font-weight-regular"
                                >{{ trans('pages.auth.authText') }}</h6>
                                <v-btn class="mt-4 text-capitalize" x-large outlined color="white" :href="backendUrl">{{ trans('buttons.common.home') }}</v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </div>
                <img src="@/assets/images/cpaider-down.svg" style="position: absolute; top: 0; right: 0; margin-right: 15px;">
            </v-container>
        </v-col>
        <v-col cols="12" lg="5" xl="6" class="d-flex align-center">
            <v-container>
                <img src="@/assets/images/cpaider-down.svg" style="position: absolute; top: 0; right: 0; margin-right: 15px; max-width: 70px" class="d-block d-md-none">
                <div class="pa-7 pa-sm-12">
                    <v-row>
                        <v-col cols="12">
                            <lang-switcher></lang-switcher>
                        </v-col>
                        <v-col cols="12" lg="9" xl="6" class="text-center">

                            <v-form ref="form" v-model="valid" lazy-validation >
                                <v-btn
                                    color="info"
                                    block
                                    dark
                                    class="mr-4"
                                    @click="submit"
                                >{{ trans('pages.buttons.signInWorkle') }}</v-btn>
                                <div class="d-block d-sm-flex align-center mb-4 mb-sm-0">
                                    <v-checkbox
                                            v-model="checkbox"
                                            :rules="[v => !!v || trans('pages.auth.agree')]"
                                            :label="trans('pages.fields.agreeTerms')"
                                            required
                                    >
                                        <template slot="append">
                                            <a target="_blank"  :href="route('policy', $store.getters.curlocale)">{{ trans('pages.fields.termsText') }}</a>
                                        </template>
                                    </v-checkbox>
                                </div>

                            </v-form>
                        </v-col>
                    </v-row>
                </div>
            </v-container>
        </v-col>
    </v-row>
</template>

<script>
    import LangSwitcher from "@/components/commonComponents/LangSwitcher";
    const env = require('@/env.json');
    // const axios = window.axios;
    export default {
        name: "Workle",
        components: {LangSwitcher},
        data: () => ({
            valid: true,
            show1: false,
            checkbox: false,
            errors:{},
        }),
        created() {
        },
        computed: {
            backendUrl() {
                return env.backendUrl;
            }
        },
        methods: {
            submit() {
                this.$refs.form.validate();
                if (this.$refs.form.validate(true)) {
                    window.location.href = this.route('auth.workle.getAuth');
                }
            }
        }
    };
</script>
<style scoped>
    .leftside {
        background: url("../../../assets/images/web-web.svg") no-repeat center right;
        /*background-image: linear-gradient(-90deg, #1d88e5 0%, #3998ea 100%);*/
    }
</style>
